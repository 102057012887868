import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import useAuth from '../../hooks/UseAuth'
import { getAbsences } from '../../actions/absenceActions'
import { CustomDataTable } from '../../components/datatables/CustomDataTable'
import CreateAdminAbsence from '../modals/CreateAdminAbsence'

export default function ListAbsences() {
	const { t } = useTranslation()
	const dispatch = useDispatch()
	const {auth} = useAuth()

	const {absences} = useSelector(state => state.absences)

	useEffect(() => {
		if(auth){
			dispatch(getAbsences())
		}
	}, [auth, dispatch])

	const columns = [
		{
			name: t('Ausencia'),
			cell: absence =>
				<div className="d-flex align-items-center">
					<div className="symbol symbol-circle symbol-50px overflow-hidden me-3">
						<Link to={`/absence/${absence.id}`}>
						<div className="symbol-label fs-3 bg-light-danger text-danger">{absence.reason?.charAt(0)}</div>
						</Link>
					</div>
					<div className="d-flex flex-column">
						<Link to={`/absence/${absence.id}`} className="text-gray-800 text-hover-primary mb-1">
						{absence.reason}
						</Link>
					</div>
				</div>,
			selector: (payroll) => payroll.title,
			sortable: true,
		},{
			name: t('Tipo'),
			cell: absence =>
				<div>
					{absence.absenceType}
				</div>,
			selector: (absence) => absence.absenceType,
			sortable: true,
		},{
			name: t('Usuario'),
			cell: absence =>
				<div>
					<Link to={`/user/${absence.user.id}`} className="text-gray-800 text-hover-primary mb-1">
						{absence.user.username}
					</Link>
				</div>,
			selector: (absence) => absence.user.username,
			sortable: true,
		},{
			name: t('Fecha de Inicio'),
			cell: absence =>
				<div>
					{absence.startDate}
				</div>,
			selector: (absence) => absence.startDate,
			sortable: true,
		},{
			name: t('Hora de Inicio'),
			cell: absence =>
				<div>
					{absence.startHour || '-'}
				</div>,
			selector: (absence) => absence.startHour || '-',
			sortable: true,
		},{
			name: t('Fecha de Fin'),
			cell: absence =>
				<div>
					{absence.endDate}
				</div>,
			selector: (absence) => absence.endDate,
			sortable: true,
		},{
			name: t('Hora de Fin'),
			cell: absence =>
				<div>
					{absence.endHour}
				</div>,
			selector: (absence) => absence.endHour,
			sortable: true,
		},{
			name: t('Estado'),
			cell: absence =>
				<div>
					<span className={`badge fs-8 fw-bolder ${absence.status === 'Aceptada' ? 'badge-light-success' : absence.status === 'Denegada' ? 'badge-light-danger' : 'badge-light-info'}`}>{t(absence.status)}</span>
				</div>,
			selector: (absence) => absence.status,
			sortable: true,
		}
	]

	return (
		<>
			<div className="toolbar py-5 py-lg-15" id="kt_toolbar">
				<div id="kt_toolbar_container" className="container-xxl d-flex flex-stack flex-wrap">
					<div className="page-title d-flex flex-column me-3">
						<h1 className="d-flex text-white fw-bolder my-1 fs-3">{t('Listar Ausencias')}</h1>
						<ul className="breadcrumb breadcrumb-separatorless fw-bold fs-7 my-1">
							<li className="breadcrumb-item text-white opacity-75">
								<Link to='/' className="text-white text-hover-primary">{t('Inicio')}</Link>
							</li>
							<li className="breadcrumb-item">
								<span className="bullet bg-white opacity-75 w-5px h-2px m-1" />
							</li>
							<li className="breadcrumb-item text-white">{t('Listar Ausencias')}</li>
						</ul>
					</div>
				</div>
			</div>
			<div id="kt_content_container" className="d-flex flex-column-fluid align-items-start container-xxl">
				<div className="content flex-row-fluid" id="kt_content">
					<div className="card">
						<div className="card-header border-0 pt-6">
                            <div className="card-toolbar">
                                <CreateAdminAbsence button />
                            </div>
                        </div>
						<div className="card-body py-4">
							<div className='table-responsive'>
								{absences?.length > 0
									?
										<CustomDataTable columns={columns} data={absences} />
									:
										<></>
								}
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	)
}