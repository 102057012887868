import React, { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useParams } from 'react-router-dom'
import { editAbsence, getAbsence } from '../../actions/absenceActions'
import { SuccessPopup } from '../../components/popups/Popups'

import { useTranslation } from 'react-i18next'

export default function Absence() {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const {register, handleSubmit, watch, setValue} = useForm()

  const { id } = useParams()
  const {absence} = useSelector(state => state.absences)
  
  const data = {
	status: watch('absenceStatus'),
  }

  useEffect(()=>{
	dispatch(getAbsence(id))
  }, [])

  useEffect(()=>{
	setValue('absenceStatus', absence?.status)
  }, [setValue, absence])

  const UpdateAbsenceStatus = async() => {
	dispatch(editAbsence(data, id))
	SuccessPopup(`Estado de la ausencia modificado correctamente!`)
  }
  
	return (
		<>
			<div className="toolbar py-5 py-lg-15" id="kt_toolbar">
				<div id="kt_toolbar_container" className="container-xxl d-flex flex-stack flex-wrap">
					<div className="page-title d-flex flex-column me-3">
						<h1 className="d-flex text-white fw-bolder my-1 fs-3">{t('Ausencia')}</h1>
						<ul className="breadcrumb breadcrumb-separatorless fw-bold fs-7 my-1">
							<li className="breadcrumb-item text-white opacity-75">
								<Link to='/' className="text-white text-hover-primary">{t('Inicio')}</Link>
							</li>
							<li className="breadcrumb-item">
								<span className="bullet bg-white opacity-75 w-5px h-2px m-1" />
							</li>
							<li className="breadcrumb-item text-white opacity-75">
								<Link to='/absence/list' className="text-white text-hover-primary">{t('Listar Ausencias')}</Link>
							</li>
							<li className="breadcrumb-item">
								<span className="bullet bg-white opacity-75 w-5px h-2px m-1" />
							</li>
							<li className="breadcrumb-item text-white">{t('Ausencia')}</li>
						</ul>
					</div>
				</div>
			</div>
			<div id="kt_content_container" className="d-flex flex-column-fluid align-items-start container-xxl">
				<div className="content flex-row-fluid" id="kt_content">
					<div className="row">
						<div className="col-6">
							<div className="card card-flush py-4">
								<div className="card-header">
									<div className="card-title">
										<h2>{absence?.reason}</h2>
									</div>
								</div>
								<div className="card-body pt-0">
									<div className="d-flex flex-stack fs-4 py-3">
										<div className="fw-bolder rotate collapsible" data-bs-toggle="collapse" href="#kt_user_view_details" role="button" aria-expanded="false" aria-controls="kt_user_view_details">Detalles
											<span className="ms-2 rotate-180">
												<span className="svg-icon svg-icon-3">
													<svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none">
														<path d="M11.4343 12.7344L7.25 8.55005C6.83579 8.13583 6.16421 8.13584 5.75 8.55005C5.33579 8.96426 5.33579 9.63583 5.75 10.05L11.2929 15.5929C11.6834 15.9835 12.3166 15.9835 12.7071 15.5929L18.25 10.05C18.6642 9.63584 18.6642 8.96426 18.25 8.55005C17.8358 8.13584 17.1642 8.13584 16.75 8.55005L12.5657 12.7344C12.2533 13.0468 11.7467 13.0468 11.4343 12.7344Z" fill="black" />
													</svg>
												</span>
											</span></div>
										<span data-bs-toggle="tooltip" data-bs-trigger="hover" title="Edit customer details">
										</span>
									</div>
									<div className="separator" />
									<div id="kt_user_view_details" className="collapse show">
										<div className="pb-5 fs-6">
											{absence?.absenceType
											? 
												<>
													<div className="fw-bolder mt-5">{t('Tipo')}</div>
													<div className="text-gray-600">{absence.absenceType}</div>
												</>
											: 
												''
											}

											{absence?.user
											?
												<>
													<div className="fw-bolder mt-5">{t('Usuario')}</div>
													<Link to={`/user/${absence.user?.id}`} className="text-gray-600 text-hover-primary mb-1">
														{absence.user?.username}
													</Link>
												</>
											:
												''
											}

											{absence?.startDate
											?
												<>
													<div className="fw-bolder mt-5">{t('Fecha de inicio')}</div>
													<div className="text-gray-600">{absence.startDate}</div>
												</>
											:
												''
											}

											{absence?.startHour
											?
												<>
													<div className="fw-bolder mt-5">{t('Hora de inicio')}</div>
													<div className="text-gray-600">{absence.startHour}</div>
												</>
											:
												''
											}

											{absence?.endDate
											?
												<>
													<div className="fw-bolder mt-5">{t('Fecha de fin')}</div>
													<div className="text-gray-600">{absence.endDate}</div>
												</>
											:
												''
											}

											{absence?.endHour
											?
												<>
													<div className="fw-bolder mt-5">{t('Hora de fin')}</div>
													<div className="text-gray-600">{absence.endHour}</div>
												</>
											:
												''
											}

											{absence?.status
											?
												<>
													<div className="fw-bolder mt-5">{t('Estado')}</div>
													<div className="text-gray-600">{absence.status}</div>
												</>
											:
												''
											}
										</div>
									</div>
								</div>
							</div>
						</div>
						<div className="col-3">
							<div className="card card-flush py-4">
								<div className="card-header">
									<div className="card-title">
										<h2>{t('Estado')}</h2>
									</div>
									<div className="card-toolbar">
										<div className="rounded-circle bg-success w-15px h-15px" id="kt_ecommerce_add_category_status" />
									</div>
								</div>
								<form className="card-body pt-0" onSubmit={handleSubmit(UpdateAbsenceStatus)}>
									<select className="form-select mb-2" data-hide-search="true" {...register('absenceStatus')}>
										<option value={"Pendiente de revisión"}>{t('Pendiente de revisión')}</option>
										<option value={"Aceptada"}>{t('Aceptada')}</option>
										<option value={"Denegada"}>{t('Denegada')}</option>
									</select>
									<div className="text-muted fs-7">{t('Cambiar el estado de la ausencia')}.</div>
									<button className='btn btn-primary py-2 px-3 mt-5'>{t('Guardar')}</button>
								</form>
							</div>
						</div>
					</div>
					<div className="d-flex flex-column flex-row-fluid gap-7 gap-lg-10">
					</div>
				</div>
			</div>
		</>
	)
}
